import { DATE_FORMAT } from '@asaprint/asap/locales/client.js';
import CenteredCell from '@asaprint/asap/components/tables/CenteredCell.js';
import { useLocale } from '@engined/client/contexts/LocaleContext.js';
import { format } from 'date-fns';
import React from 'react';
import { CellProps } from 'react-table';

interface OwnProps {
  className?: string;
}

type Props = OwnProps & CellProps<any>;

const DateCell: React.FunctionComponent<Props> = ({ className, cell: { value } }) => {
  const { t } = useLocale();

  return <CenteredCell className={className}>{value ? format(new Date(value), t(DATE_FORMAT)) : '-'}</CenteredCell>;
};

DateCell.displayName = 'DateCell';

export default React.memo(DateCell);
