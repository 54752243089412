import useEventCallback from '@engined/client/hooks/useEventCallback.js';
import { DATE_FORMAT } from '@engined/client/locales.js';
import { DateFilter as SchemaDateFilter } from '@asaprint/asap/schema.client.types.js';
import Input, { InputProps } from '@engined/client/components/table/Input.js';
import { FilterComponent } from '@asaprint/asap/components/tables/interfaces.js';
import React from 'react';

const DateFilter: FilterComponent<any, SchemaDateFilter> = ({ column }) => {
  const { filterValue, setFilter } = column;

  const onChangeCallback = useEventCallback<InputProps['onChange']>((event) => {
    setFilter(event.currentTarget.value);
  });

  return (
    <Input
      type="text"
      value={filterValue || ''}
      onChange={onChangeCallback}
      placeholder={(column as any).Placeholder}
      name={column.id}
    />
  );
};

DateFilter.displayName = 'DateFilter';

DateFilter.filterToGraphQLVariable = (value: string, language: string) => {
  return {
    format: DATE_FORMAT[language],
    like: `%${value}%`,
    tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
};

export default React.memo(DateFilter);
